import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const WaitlistConfirmationPage = () => (
  <Layout>
    <SEO title="Waitlist Confirmation"/>
    <h1 style={{ marginTop: "2rem" }}>Thank you for joining our waitlist</h1>
    <div style={{ margin: "1rem"}}>
      <span style={{ borderRadius: "5px", backgroundColor: "#27C495", padding: ".5rem .7rem" }}>Success! Now check your email to confirm</span>
    </div>
    <p><strong>Our product goals:</strong></p>
    <ul>
      <li><span>Make migration from Substack as <strong>simple and easy as possible</strong></span></li>
      <li><span>Give you a solution that you <strong>100% own and control</strong></span></li>
      <li><span>Provide tooling to help <strong>automate</strong> as much of your <strong>newsletter + website</strong> flow as possible</span>
      </li>
      <li><span>Help with <strong>best practices</strong> for things like SEO, landing page conversions, creating good content</span>
      </li>
      <li><span><strong>Free</strong> monthly hosting costs</span></li>
    </ul>
    <hr style={{borderTop: "1px solid white"}}/>
    <p><strong>Have suggestions or feedback?</strong></p>
    <p>Reach out to <span style={{ color: "#27c495"}}><strong>justin@unpublisher.co</strong></span></p>
  </Layout>
)

export default WaitlistConfirmationPage